import { AfterViewInit, Directive, Input, NgModule } from '@angular/core';

@Directive({
  selector: 'img',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(error)': 'setFallBackUrl()',
    '[src]': 'src',
  },
  standalone: true,
})
export class FallBackImgDirective implements AfterViewInit {
  @Input() src: string | undefined;

  private readonly fallBackUrl: string = '/assets/image-placeholder.svg';

  ngAfterViewInit(): void {
    if (!this.src) {
      this.setFallBackUrl();
    }
  }

  private setFallBackUrl(): void {
    this.src = this.fallBackUrl;
  }
}

@NgModule({
  imports: [FallBackImgDirective],
  exports: [FallBackImgDirective],
})
export class FallBackImgModule {}
